h2{
    font-size: 2.25rem;
    font-weight: 600;
    margin-bottom: 2rem;
    color: #000;
}

h3{
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: 1.25rem;
    color: #000;
}

h4{
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #000;
}

h5{
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #000;
}

h6{
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #000;
}