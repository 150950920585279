a {
  display: flex;
  gap: 0.3rem;
  align-items: center;
  color: $primary-color;
  transition: 0.2s;
  min-width: fit-content;

  &.order {
    color: $primary-dark-color;
  }

  mat-icon {
    display: block;
    min-width: fit-content;
  }

  span {
    min-width: fit-content;
    display: block;
  }

  .arrow {
    transition: 0.2s;
  }

  &:hover {
    cursor: pointer;
    color: $primary-dark-shade;

    .arrow {
      margin-left: 0.2rem;
    }
  }
}

.mat-mdc-button {
  font-weight: bold;
}

/* Buttons */
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  color: $primary-contrast;
  background: $primary-color;
  padding: 1rem 2rem;
  border-radius: 30px;
  border: none;
  font-weight: bold;
  transition: 0.2s;
  position: relative;

  &:focus {
    outline-color: $primary-shade;
  }

  &[disabled] {
    background: $medium-tint;
    color: $medium-contrast;
  }

  &:not([disabled]) {
    @include desktop {
      &:hover {
        background: $primary-shade;
        cursor: pointer;
      }
    }
  }

  &:has(.mat-calendar-body-cell-content) {
    padding: 0;
  }

  // different type of buttons
  &.slim {
    padding: 0.5rem 1rem;
  }

  &:not([disabled]).secondary {
    background: $secondary-color;
    color: $secondary-contrast;

    &:not([disabled]):hover {
      background-color: $secondary-shade;
    }
  }

  &:not([disabled]).tertiary {
    background: $tertiary-color;
    color: $tertiary-contrast;

    &:not([disabled]):hover {
      background-color: $tertiary-shade;
    }
  }

  &:not([disabled]).quaternary {
    background: $quaternary-color;
    color: $quaternary-contrast;

    &:not([disabled]):hover {
      background-color: $quaternary-shade;
    }
  }

  &:not([disabled]).danger {
    background: $danger-color;
    color: $danger-contrast;

    &:not([disabled]):hover {
      background-color: $danger-shade;
    }
  }

  &:not([disabled]).warning {
    background: $warning-color;
    color: $warning-contrast;

    &:not([disabled]):hover {
      background-color: $warning-shade;
    }
  }

  &:not([disabled]).success {
    background: $success-color;
    color: $success-contrast;

    &:not([disabled]):hover {
      background-color: $success-shade;
    }
  }

  &.with-icon {
    padding-right: 1.5rem;
  }

  &.loading {
    position: relative;
    background: $medium-shade !important;
    color: $dark-shade !important;

    &:hover {
      background: $medium-shade !important;
      cursor: initial;
    }

    mat-spinner {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
    }
  }
}

/* End of buttons */

/* Login form */
form.login-form {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 700px;

  label {
    border-bottom: solid 3px $light-tint;
    transition: 0.2s;

    input {
      display: block;
      padding: 0.5rem;
      border: none;
      width: 100%;
      background-color: transparent !important;

      &:focus {
        outline: none;
        background-color: transparent !important;
      }

      &:hover {
        @include desktop {
          cursor: pointer;
        }
      }

      &:-internal-autofill-selected {
        background-color: transparent !important;
        appearance: none !important;
      }
    }

    /* Remove default blue backgground on autofill */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    }

    &:hover {
      @include desktop {
        cursor: pointer;
        border-color: $primary-color;
      }
    }

    &:focus-within {
      border-color: $primary-color;
    }

    &:has(input.ng-dirty.ng-invalid) {
      border-color: $danger-color;

      input {
        border-color: $danger-color;
      }
    }
  }

  button {
    margin: 2rem auto 0 auto;
  }
}

form {
  .form-fieldset {
    display: flex;
    gap: 1rem;
  }

  mat-form-field {
    width: 100%;
  }
}

input[type='search'] {
  padding: 0.5rem 1rem;
  border: solid 2px $primary-tint;
  border-radius: 20px;
  width: 400px;
  margin: 1rem 0;

  &[disabled] {
    border-color: $medium-color;
  }
}

/* Angular material */

.mat-mdc-table {
  margin-top: 2rem;

  .mat-mdc-row {
    transition: 0.2s;

    &:hover {
      background: $light-tint;
      cursor: pointer;
    }
  }

  .mat-mdc-footer-cell {
    font-weight: 500;
    border-top-color: var(
      --mat-table-row-item-outline-color,
      rgba(0, 0, 0, 0.12)
    );
    border-top-width: var(--mat-table-row-item-outline-width, 1px);
    border-top-style: solid;
  }
}

.mat-icon-button {
  transition: 0.2s;
  border-radius: 50%;
  background: $primary-color;
  color: $primary-contrast;
  padding: 0.8rem;
  display: flex;
  align-items: center;

  mat-icon {
    width: 24px;
    height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
  }

  &.active {
    background: $primary-shade;
  }

  &:hover {
    cursor: pointer;
    background: $primary-shade;
  }
}

.mat-icon-bullet {
  @extend.mat-icon-button;
  width: 35px;
  height: 35px;
  font-size: 0.3rem;
  padding: 0.2rem;
  justify-content: center;

  &:hover {
    cursor: initial;
    background: $primary-color;
  }

  mat-icon {
    font-size: 1.2rem;
    font-weight: bold;
    width: 20px;
    height: 20px;
  }

  &.small {
    width: 25px;
    height: 25px;
    mat-icon {
      font-size: 1rem;
      font-weight: bold;
      width: 15px;
      height: 15px;
    }
  }
}

mat-accordion {
  display: block;

  mat-expansion-panel {
    box-shadow: 0px 0px 4px $light-color !important;

    &.simple {
      box-shadow: none !important;
    }
  }
}

.mat-button-toggle {
  button {
    border-radius: inherit;

    .mat-button-toggle-label-content {
      line-height: 2;
    }

    &:hover {
      background: $primary-tint;
    }
  }
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  &.number-data {
    text-align: end;
  }
}

mat-paginator.mat-mdc-paginator {
  font-size: 0.8rem;
}

mat-form-field {
  /* Slightly smaller input fields, keeps placeholder */
  @include mat.all-component-densities(-1);
}

// Info-box
.info-icon {
  position: relative;
  align-self: end;
  display: grid;
  place-items: center;
  width: fit-content;

  mat-icon {
    color: $primary-dark-color;
    background-color: $primary-dark-contrast;
  }

  .show-on-info-icon-hover {
    display: none;
    position: absolute;
    bottom: -0.5rem;
    left: 2.5rem;
    padding: 0.5em 1em 1em 1em;
    border-radius: 1em;
    min-width: 250px;
    background-color: #fff;
    box-shadow: 0 0 20px $medium-color;
    font-size: smaller;
    z-index: 20;

    h4 {
      text-align: center;
    }
    p {
      margin-bottom: 0.5em;
    }
    ul {
      margin-left: 1.5em;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 14px;
      left: -9px;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid #fff;
    }
  }

  &:hover .show-on-info-icon-hover {
    display: block;
  }
}

/*  End of info-box */

/* Information section like booking/order detail page */
.info-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  ul {
    list-style-type: none;
    width: 100%;
    display: block;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      padding: 1rem;
      width: 100%;
      border-bottom: solid 1px $light-color;
      color: $dark-shade;

      &:last-of-type {
        border-bottom: none;
      }

      &:has(mat-expansion-panel) {
        padding: 0.5rem 0;
      }

      span {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        &:first-of-type {
          width: 100%;
        }

        &:last-of-type {
          width: fit-content;
          min-width: fit-content;
        }

        &.value-to-end {
          justify-content: flex-end;
          color: black;
          font-weight: 500;
        }
      }
    }
  }
}

/* Empty content like no search result or error content like couldn't get flights */
.empty-content,
.error-content {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: center;
  border-radius: 10px;
  margin: auto;
}

.empty-content {
  background: $light-color;
  color: $dark-shade;
}

.error-content {
  flex-direction: column;
  background: $quaternary-tint;
  color: $danger-shade;
}
