/**
* This file contains palettes for the following colors
* - Primary
* - Secondary
* - Tertiary
* - Quaternary
* - Danger
* - Success
*
* Guide for picking a palette, https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
*/

$primary-color-palette: (
  50: #e7f1fe,
  100: #c4d6fa, // $primary-tint
  200: #a0b6df, // $primary-dark-tint
  300: #8ab1f8, // $primary-color
  400: #7b9ff4,
  500: #678fd7, // $primary-shade
  600: #4984d2,
  700: #4271bf, // $primary-dark-color
  800: #3c61ad,
  900: #31579e, // $primary-dark-shade
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
  ),
);

$secondary-color-palette: (
  50: #fefce5,
  100: #fdf8be,
  // $secondary-tint
  200: #fcf393,
  300: #faee66,
  // $secondary-color
  400: #f8e93f,
  // $secondary-shade
  500: #f5e40a,
  600: #f7d308,
  700: #f7bb00,
  800: #f6a200,
  900: #f47800,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: #000,
    700: #000,
    800: #000,
    900: #000,
  ),
);

$tertiary-color-palette: (
  50: #e6e3f3,
  // $tertiary-tint
  100: #c1bbe1,
  // $tertiary-color
  200: #978fcd,
  // $tertiary-shade
  300: #6d63bb,
  400: #4b45ad,
  500: #1d29a0,
  600: #07259b,
  700: #001f93,
  800: #001a8a,
  900: #00107d,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #fff,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
  ),
);

$quaternary-color-palette: (
  50: #fbe3df,
  // $quaternary-tint
  100: #fbbfa7,
  // $quaternary-color
  200: #f29672,
  // $quaternary-shade
  300: #e66f3e,
  400: #de5210,
  500: #d73200,
  600: #cd2d00,
  700: #c12600,
  800: #b31d00,
  900: #9c0a00,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
  ),
);

$danger-color-palette: (
  50: #fdebee,
  100: #fbccd2,
  200: #e9999a,
  300: #dd7273,
  400: #e65352,
  500: #ea4439,
  600: #db3b38,
  700: #c93132,
  // $danger-tint
  800: #bc2b2b,
  // $danger-color
  900: #ad2120,
  // $danger-shade
  contrast:
    (
      50: #000,
      100: #000,
      200: #000,
      300: #000,
      400: #fff,
      500: #fff,
      600: #fff,
      700: #fff,
      800: #fff,
      900: #fff,
    ),
);

$success-color-palette: (
  50: #e4eee2,
  // $success-tint
  100: #cedacc,
  // $success-color
  200: #b1ceab,
  // $success-shade
  300: #8ba485,
  400: #43693c,
  500: #003700,
  600: #001f00,
  700: #001800,
  800: #001700,
  900: #001600,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
  ),
);

$grey-scale-palette:(
  50: #fbfbfb,
  100: #f6f6f6,
  200: #f1f1f1,
  300: #e6e6e6,
  400: #c4c4c4,
  500: #a5a5a5,
  600: #7c7c7c,
  700: #676767,
  800: #484848,
  900: #262626,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
  ),
);