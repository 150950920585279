// Custom Theming for Angular Material
@use "@angular/material" as mat;

// Be sure that you only ever include this mixin once!
@include mat.core();

/* Palettes */
@import "partials/palettes";

// Primary
$parking-admin-primary: mat.define-palette(
  $primary-color-palette,
  300,
  100,
  500
);
// Primary-dark
$parking-admin-primary-dark: mat.define-palette(
  $primary-color-palette,
  700,
  200,
  900
);
// Secondary
$parking-admin-secondary: mat.define-palette(
  $secondary-color-palette,
  300,
  100,
  400
);
// Tertiary
$parking-admin-tertiary: mat.define-palette(
  $tertiary-color-palette,
  100,
  50,
  200
);
// Quaternary
$parking-admin-quaternary: mat.define-palette(
  $quaternary-color-palette,
  100,
  50,
  200
);
// Danger
$parking-admin-danger: mat.define-palette($danger-color-palette, 800, 700, 900);
// Warning
$parking-admin-warning: mat.define-palette(
  $secondary-color-palette,
  800,
  700,
  900
);
// success
$parking-admin-success: mat.define-palette(
  $success-color-palette,
  100,
  50,
  200
);
// Dark-grey
$parking-admin-dark-grey: mat.define-palette($grey-scale-palette, 900);

/* Theme */
// Color only takes three key value pairs, primary, accent and warn
$parking-admin-theme: mat.define-light-theme(
  (
    color: (
      primary: $parking-admin-primary,
      accent: $parking-admin-secondary,
      warn: $parking-admin-danger,
    ),
  )
);

/**
* To be able to use more palettes we can create different themes to be used for different components
* by setting differetn values to the tree keys, eg. for buttons using quaternary as accent color.  
*
* $parking-admin-button-theme: mat.define-light-theme((color: (
*   primary: $parking-admin-primary,
*   accent: $parking-admin-quaternary,
*   warn: $parking-admin-danger
* )));
*/
$parking-admin-input-theme: mat.define-light-theme(
  (
    color: (
      primary: $parking-admin-primary-dark,
      accent: $parking-admin-quaternary,
      warn: $parking-admin-danger,
    ),
  )
);

$parking-admin-toolbar-theme: mat.define-light-theme(
  (
    color: (
      primary: $parking-admin-primary,
      accent: $parking-admin-primary-dark,
      warn: $parking-admin-danger,
    ),
  )
);

$parking-admin-checkbox-theme: mat.define-light-theme(
  (
    color: (
      primary: $parking-admin-primary,
      accent: $parking-admin-success,
      warn: $parking-admin-secondary,
    ),
  )
);

@include mat.all-component-themes($parking-admin-theme);
@include mat.form-field-color($parking-admin-input-theme);
@include mat.toolbar-color($parking-admin-toolbar-theme);
@include mat.checkbox-color($parking-admin-checkbox-theme);
@include mat.chips-color($parking-admin-checkbox-theme);
@include mat.radio-color($parking-admin-checkbox-theme);
/**
* To use an alternative theme for different components @Include the component-color(alternative theme)
*
* @include mat.button-color($parking-admin-button-theme);
*/

@import "partials/variables";
@import "partials/mixins";
@import "partials/components";
@import "partials/fonts";

/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700&display=swap");

* {
  box-sizing: border-box;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  font-family: Outfit, sans-serif;
}

body {
  margin: 0;

  .wrapper {
    margin: 0 auto;
    padding: 3rem 2rem 10rem 2rem;
    width: 100%;
    max-width: 1500px;
  }
  .wrapper-no-layout {
    margin: 0 auto;
    padding: 3rem 2rem 3rem 2rem;
    width: 100%;
  }
}
