/* colors */
$default-color: #fff4df;
$default-tint: #fcf3e3;
$default-shade: #fce7c4;
$default-contrast: #e0b970;

$primary-color: #8ab1f8;
$primary-tint: #c4d6fa;
$primary-shade: #678fd7;
$primary-contrast: #000;

$primary-dark-color: #4271bf;
$primary-dark-tint: #a0b6df;
$primary-dark-shade: #31579e;
$primary-dark-contrast: #fff;

$secondary-color: #faee66;
$secondary-tint: #fdf8be;
$secondary-shade: #f8e93f;
$secondary-contrast: #000;

$tertiary-color: #c1bbe1;
$tertiary-tint: #e6e3f3;
$tertiary-shade: #978fcd;
$tertiary-contrast: #000;

$quaternary-color: #fbbfa7;
$quaternary-tint: #fbe3df;
$quaternary-shade: #f29672;
$quaternary-contrast: #000;

$danger-color: #bc2b2b;
$danger-tint: #c93132;
$danger-shade: #ad2120;
$danger-contrast: #fff;

$warning-color: #f6a200;
$warning-tint: #f7bb00;
$warning-shade: #f47800;
$warning-contrast: #000;

$success-color: #cedacc;
$success-tint: #e4eee2;
$success-shade: #b1ceab;
$success-darker: #769570;
$success-contrast: #000;

$light-color: #e6e6e6;
$light-tint: #eeeeee;
$light-shade: #d4d4d4;
$light-contrast: #000;

$medium-color: #b6b6b6;
$medium-tint: #c7c7c7;
$medium-shade: #a1a1a1;
$medium-contrast: #000;

$dark-color: #797979;
$dark-tint: #a7a7a7;
$dark-shade: #707070;
$dark-contrast: #fff;

$darker-color: #414141;
$darker-tint: #636363;
$darker-shade: #2c2c2c;
$darker-contrast: #fff;
